<template>
<div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
        <div class="container fluid">
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="ecscleaners()">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:50%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/materials/spray_solution/ecs_cleaner.png"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                ECS-Cleaners
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a  @click="protectivelubricating()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:50%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/materials/spray_solution/Protective.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Protective Lubricating Agent
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="lacquers()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:50%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/materials/spray_solution/Lacquers.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Lacquers
              </h4>
            </div>
          </div>
          </a>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="specialspray()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:50%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/materials/spray_solution/Special.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Special Spray
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="elixhandcleaning()">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:50%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/materials/spray_solution/Elix.png"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Elix Hand Cleaning Wipes
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        </div>
      </div>
         <Footer />
        </div>
</template>
<script>

import Header from '../../../components/Header.vue';

import Footer from '../../../components/Footer.vue';

/**
 * Dashboard component
 */
export default {
  components: {
   
    Header,
  
    Footer
    
   
  },
  methods: {
    ecscleaners(){
      window.location.href = "https://ecs-chemicals.com/ecs-cleaners/";

    },

protectivelubricating(){
  window.location.href = "https://ecs-chemicals.com/protective-lubricating-agent/";

    },

lacquers(){
  window.location.href = "https://ecs-chemicals.com/lacquers/";

    },

specialspray(){
  window.location.href = "https://ecs-chemicals.com/special-sprays/";
 
    },

elixhandcleaning(){
  window.location.href = "https://ecs-chemicals.com/elix-hand-cleaning-wipes/";

    },
  },
  
};
</script>